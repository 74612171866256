var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"circle-in-layout",class:_vm.wheelFloat=='right' ? 'circle-right' : ''},[_c('div',{ref:"circleWrapper",staticClass:"main_circle d-xl-block d-none",style:({
      'animation-play-state': _vm.animationMovePlayState,
      transform: _vm.translateY,
      transition: _vm.transition,
      '-webkit-transition': _vm.transition,
      '-moz-transition': _vm.transition,
      '-ms-transition': _vm.transition,
      '-o-transition': _vm.transition,
    }),on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false},"click":function($event){_vm.hidden = !_vm.hidden}}},[_c('div',{staticClass:"circle",class:{
        circle__animation_hover: _vm.circleAnimationHover,
        circle__hide_right: _vm.hiddenRight,
        circle__hide_left: _vm.hiddenLeft
      }},[_c('div',{on:{"click":_vm.actionRegister}},[_c('span',{staticClass:"w0"},[_vm._v("C")]),_c('span',{staticClass:"w1"},[_vm._v("L")]),_c('span',{staticClass:"w2"},[_vm._v("I")]),_c('span',{staticClass:"w3"},[_vm._v("C")]),_c('span',{staticClass:"w4"},[_vm._v("K")]),_c('span',{staticClass:"w5"}),_c('span',{staticClass:"w6"},[_vm._v("T")]),_c('span',{staticClass:"w7"},[_vm._v("O")]),_c('span',{staticClass:"w8"}),_c('span',{staticClass:"w9"},[_vm._v("R")]),_c('span',{staticClass:"w10"},[_vm._v("E")]),_c('span',{staticClass:"w11"},[_vm._v("G")]),_c('span',{staticClass:"w12"},[_vm._v("I")]),_c('span',{staticClass:"w13"},[_vm._v("S")]),_c('span',{staticClass:"w14"},[_vm._v("T")]),_c('span',{staticClass:"w15"},[_vm._v("E")]),_c('span',{staticClass:"w16"},[_vm._v("R")])]),_c('span',{staticClass:"w17"}),_c('span',{staticClass:"w18"}),_c('span',{staticClass:"w19"}),_c('div',{on:{"click":_vm.actionLogin}},[_c('span',{staticClass:"w20"},[_vm._v("C")]),_c('span',{staticClass:"w21"},[_vm._v("L")]),_c('span',{staticClass:"w22"},[_vm._v("I")]),_c('span',{staticClass:"w23"},[_vm._v("C")]),_c('span',{staticClass:"w24"},[_vm._v("K")]),_c('span',{staticClass:"w25"}),_c('span',{staticClass:"w26"},[_vm._v("T")]),_c('span',{staticClass:"w27"},[_vm._v("O")]),_c('span',{staticClass:"w28"}),_c('span',{staticClass:"w29"},[_vm._v("L")]),_c('span',{staticClass:"w30"},[_vm._v("O")]),_c('span',{staticClass:"w31"},[_vm._v("G")]),_c('span',{staticClass:"w32"},[_vm._v("I")]),_c('span',{staticClass:"w33"},[_vm._v("N")])])]),_c('div',{staticClass:"arrow",class:{
        arrow__hide: _vm.hiddenRight || _vm.hiddenLeft
      }},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.wheelFloat==='right' ? _vm.arrowRightIcon : _vm.arrowLeftIcon,"size":"2x"}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }