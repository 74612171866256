export default {
    debug: true,
    state: {
      message: "Hello",
      blur: true,
      footerHeight: 0,
      appMounted: false
    },
    setMessage (val) {
      this.debug('setMessage', val);
      this.state.message = val
    },
    setBlur(val) {
      this.debug('setBlur', val);
      this.state.blur = val
    },
    setFooterHeight(val) {
      this.debug('setFooterHeight', val);
      this.state.footerHeight = val
    },
    debug(fName, val) {
      
    },
    setAppMounted(val) {
      this.state.appMounted = val;
    }
  }
