<template>
  <div id="app">
    <mj-menu />
    <mj-circle />
    <router-view class="app__content"/>
    <div v-show="!getCookieConsent" class="cookies-policy"> <!--v-show=!getCookieConsent-->
      <div />
      <div>This site uses cookies. More information you can find in our <a href="terms/Landing Privacy Policy.pdf" target="blank">Privacy Policy</a>.</div>
      <b-button pill size="sm" variant="light" @click="registerConsent">I consent</b-button>
    </div>
    <div ref="footer">
      <mj-footer :class="{ app__footer_hidden: hideFooter }"/>
    </div>
  </div>
</template>

<script>
import store from "@/store.js";

import Circle from "@/components/layout/Circle.vue"
import Menu from "@/components/layout/Menu.vue";
import Footer from "@/components/layout/Footer.vue";


export default {
  name: "App",
  data() {
    return {
      state: store.state,
      hideFooter: false,
      cookieSet: true
    }
  },
  created() {
    console.log(`App is in ${process.env.NODE_ENV} mode`);
  },
  mounted() {
    store.setAppMounted(true);
    store.setFooterHeight(this.$refs.footer.clientHeight);
    this.$watch(() => this.state.blur, (val) => this.hideFooter = val);
    setInterval(this.cookieSet = false, 3000)
  },
  methods: {
    registerConsent() {
      this.$cookie.set("moonjellyCookiesConsent", true, 30)
      this.cookieSet = true
    }
  },
  computed: {
    getCookieConsent() {
      return this.$cookie.get("moonjellyCookiesConsent") || this.cookieSet
    }
  },
  components: {
    mjMenu: Menu,
    mjFooter: Footer,
    mjCircle: Circle
  }
}
</script>

<style lang="scss">

#app {
  font-family: "GoodSans-Regular", "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

::selection {
  background-color: $violet;
}

.app__footer_hidden {
  display: none
}

.app__content {
  padding-top: $content-padding-px;
}

.cookies-policy {
  font-family: 'Roboto Regular';
  background-color: #ad82ff;
  opacity: .85;
  padding: 10px;
  color: rgba(255,255,255,.9);
  display: flex; 
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  border-top: 1px solid #707070;
  transition: opacity 2s;
}

.cookies-policy > div > a {
  color: rgba(255,255,255,.9);
}

</style>
