import Vue from 'vue'
import Router from 'vue-router'
// import Main from './views/Main.vue'
// import WhatIs from './views/WhatIs.vue'
// import Manifest from './views/Manifest.vue'
// import Roadmap from './views/Roadmap.vue'
// import TechData from './views/TechData.vue'
// import Contact from './views/Contact.vue'

Vue.use(Router)

export default new Router({
    scrollBehavior (_to, _from, _savedPosition) {
        return { x: 0, y: 0 }
    },
    mode: 'history',
    routes: [
        {
            path: "/",
            name: "mainHome",
            // component: Main,
            component: () =>
                import("./views/Main.vue")
        },
        {
            path: "/what-is-moonjelly-for-user-privacy",
            name: "whatIs",
            // component: WhatIs
            component: () =>
                import("./views/WhatIs.vue")
        },
        {
            path: "/manifest-moonjelly-mission",
            name: "manifest",
            // component: Manifest
            component: () =>
                import("./views/Manifest.vue")
        },
        {
            path: "/roadmap",
            name: "roadmap",
            // component: Roadmap
            component: () =>
                import("./views/Roadmap.vue")
        },
        {
            path: "/contact-and-pricing",
            name: "contact",
            // component: Contact
            component: () =>
                import("./views/Contact.vue")
        },
        {
            path: "/register",
            name: "register",
            component: () =>
                import("./views/Register.vue")
        },
        {
            path: "/login",
            name: "login",
            component: () =>
                import("./views/Login.vue")
        },
        {
            path: "/privacy",
            name: "privacy",
            // route level code-splitting
            // this generates a separate chunk (privacy.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
                import(/* webpackChunkName: "privacy" */ "./views/Privacy.vue")
        },
        {
            path: '/:catchAll(.*)*',
            beforeEnter(_to, _from, next) {
                next({name: "mainHome"})
            }
        }
    ]
});
