<template>
  <div>
    <div class="register">
      <router-link to="/register">REGISTER</router-link> OR <router-link to="/login">LOGIN</router-link> HERE
    </div>
    <div class="footer" id="footer">
      <b-container fluid>
        <b-row class="main">
          <b-col cols="0" md="0" lg="1" xl="2" />
          <b-col md="2" lg="1" xl="1" class="image_logo d-none d-md-block">
            <img width="30" height="30" :src="logo" alt="Moonjelly Logo" />
          </b-col>
          <b-col cols="10" md="4" lg="3" xl="3" class="terms">
            <!-- <a href="#">Terms of Service</a><br /> -->
            <!-- <router-link :to="{name: 'privacy'}">Privacy Policy</router-link><br /> -->
            <a target="blank" href="terms/Landing Privacy Policy.pdf">Privacy Policy</a><br />
          </b-col>
          <b-col cols="2" class="image_logo d-block d-md-none">
            <img width="30" height="30" :src="logo" alt="Moonjelly Logo" />
          </b-col>
          <b-col cols="12" md="3" lg="3" xl="2" class="social">
            <p>
              <img alt="Email sign" src="@/assets/icons/mail.svg">
              <a href="mailto:koxntxakxt@mxoxonjelxlyx.app" onmouseover="this.href=this.href.replace(/x/g,'');"> Email</a><br />
              <img alt="Instagram portal sign" src="@/assets/icons/instagram.svg">
              <a target="blank" href="https://www.instagram.com/moonjellysolutions/" rel="nofollow"> Instagram</a><br />
              <img alt="Facebook portal sign" src="@/assets/icons/facebook.svg">
              <a target="blank" href="https://www.facebook.com/moonjellySolutions" rel="nofollow"> Facebook</a><br />
              <img alt="LinkedIn portal sign" src="@/assets/icons/linkedin.svg">
              <a target="blank" href="https://www.linkedin.com/company/moonjellysolutions/" rel="nofollow"> LinkedIn</a><br />
            </p>
          </b-col>
          <b-col cols="12" md="3" lg="3" xl="2" class="adress">
            <p>
              Moonjelly Sp. z o.o.<br />
              ul. Słowackiego 24<br />
              35-069 Rzeszów<br />
              NIP: 813-382-52-41<br />
            </p>
          </b-col>
          <b-col cols="0" md="0" lg="1" xl="2"/>
        </b-row>

        <b-row class="footer__footer text-center">
          <b-col cols="0" md="0" />
          <b-col cols="12" md="6">
            Designed with ❤️ by <a target="blank" href="http://blurbstudio.com/" rel="nofollow">Blurbstudio</a>
          </b-col>
          <b-col cols="12" md="6"
            >&copy;
            <span id="year">{{ new Date().getFullYear() }}</span> Moonjelly, All
            Rights Reserved</b-col
          >
          <b-col cols="0" md="0" />
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import whiteLogo from "@/assets/logo/mj-logo-white.svg";

export default {
  data() {
    return {
      logo: whiteLogo,
    };
  },
};
</script>

<style lang="scss" scoped>
$footer-bg-color: #3d464f;
$footer-padding-subcontent: 30px;
a {
  color: #ffffff;
}
.register {
  display: none;
  font-family: "Good Sans Regular";
}
.footer {
  background-color: $footer-bg-color;
  padding-top: $layout-padding-px;
  color: #ffffff;
  font-family: "Roboto Regular";
  z-index: 990;
  position: relative;
}

.main {
  padding-bottom: $footer-padding-subcontent;
}
.footer__footer, .footer__footer > a {
  color: #ad83ff;
  padding-bottom: 20px;
}

.footer__footer > div > a {
  color: #ad83ff;
}

a:hover {
  color: #ad83ff;
  text-decoration: none;
}

@media (max-width: 1199px) {
  .register {
    display: block;
    background: #ad83ff;
    color: #fff;
    text-align: center;
    padding: 2rem 0;
    font-size: 18px;
    z-index: 990;
    position: relative;
  }
  .register > a {
    color: #fff;
    text-decoration: underline;
  }
  .image_logo {
    padding-bottom: 10px;
  }
  .terms {
    padding-bottom: 30px;
  }
  .social {
    padding-bottom: 30px;
  }
  .adress {
    padding-bottom: 30px;
  }
}
</style>
