<template>
  <div class="circle-in-layout" :class="wheelFloat=='right' ? 'circle-right' : ''">
    <div
      :style="{
        'animation-play-state': animationMovePlayState,
        transform: translateY,
        transition: transition,
        '-webkit-transition': transition,
        '-moz-transition': transition,
        '-ms-transition': transition,
        '-o-transition': transition,
      }"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      @click="hidden = !hidden"
      ref="circleWrapper"
      class="main_circle d-xl-block d-none"
    >
      <div
        class="circle"
        :class="{
          circle__animation_hover: circleAnimationHover,
          circle__hide_right: hiddenRight,
          circle__hide_left: hiddenLeft
        }"
      >
        <div @click="actionRegister">
          <span class="w0">C</span>
          <span class="w1">L</span>
          <span class="w2">I</span>
          <span class="w3">C</span>
          <span class="w4">K</span>
          <span class="w5"></span>
          <span class="w6">T</span>
          <span class="w7">O</span>
          <span class="w8"></span>
          <span class="w9">R</span>
          <span class="w10">E</span>
          <span class="w11">G</span>
          <span class="w12">I</span>
          <span class="w13">S</span>
          <span class="w14">T</span>
          <span class="w15">E</span>
          <span class="w16">R</span>
        </div>
        <span class="w17"></span>
        <span class="w18"></span>
        <span class="w19"></span>
        <div @click="actionLogin">
          <span class="w20">C</span>
          <span class="w21">L</span>
          <span class="w22">I</span>
          <span class="w23">C</span>
          <span class="w24">K</span>
          <span class="w25"></span>
          <span class="w26">T</span>
          <span class="w27">O</span>
          <span class="w28"></span>
          <span class="w29">L</span>
          <span class="w30">O</span>
          <span class="w31">G</span>
          <span class="w32">I</span>
          <span class="w33">N</span>
        </div>
      </div>
      <div 
        class="arrow"
        :class="{
          arrow__hide: hiddenRight || hiddenLeft
        }"
      >
        <a href="#" @click.prevent>
          <font-awesome-icon :icon="wheelFloat==='right' ? arrowRightIcon : arrowLeftIcon" size="2x" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { bottomHeight, scrollTop } from "@/util/web.js";

let lastScrollTop = 0,
  isLastScrollDown = true,
  sign = 1;

const MARGIN_PX = 200,
  TRANSITION_ACTIVE = "transform .5s ease-in-out",
  TRANSITION_INACTIVE = "none"

export default {
  data() {
    return {
      hidden: false,
      hiddenLeft: false,
      hiddenRight: false,
      hover: false,
      animationMovePlayState: false,
      circleAnimationHover: false,
      translateYPx: 0,
      transition: "",
      wheelFloat: "right"
    };
  },
  props: ["active"],
  computed: {
    arrowLeftIcon() {
      return faArrowLeft;
    },
    arrowRightIcon() {
      return faArrowRight;
    },
    translateY() {
      return "translateY(" + this.translateYPx + "px)";
    }
  },
  watch: {
    hover(val) {
      this.circleAnimationHover = this.hidden ? false : val;
    },
    hidden(val) {
      this.circleAnimationHover = false;
      this.hiddenLeft = val ? (this.wheelFloat === "right" ? false : true) : false;
      this.hiddenRight = val ? (this.wheelFloat === "right" ? true : false) : false;
    },
    async $route() {
      if (this.translateYPx > bottomHeight()) {
        // console.log(bottomHeight())
        this.translateYPx = await bottomHeight() - MARGIN_PX
      }
    }
  },
  created() {
    setInterval(this.handleMoveAnimation, 1000);
  },
  components: {
    FontAwesomeIcon
  },
  methods: {
    handleMoveAnimation() {
      if (window.screen.width > 1024) {
        const top = scrollTop(),
          bottom = bottomHeight();

        if (!this.hidden) {
          if (top != lastScrollTop && top > lastScrollTop) {
            // scroll down
            isLastScrollDown = true;
            sign = 1;
          } else if (top != lastScrollTop && top < lastScrollTop) {
            // scroll up
            isLastScrollDown = false;
            sign = -1;
          }
          lastScrollTop = top;

          if (!this.hover) {
            if (isLastScrollDown && this.translateYPx > bottom) {
              //wheel is going down and reach out bottom of the page
              this.transition = TRANSITION_INACTIVE;
              this.translateYPx = lastScrollTop - MARGIN_PX;
              if (this.wheelFloat === 'left') {
                this.wheelFloat = 'right'
              } else if (this.wheelFloat === 'right') {
                this.wheelFloat = 'left'
              }
            } else if (
              !isLastScrollDown &&
              this.translateYPx <= lastScrollTop - MARGIN_PX
            ) {
              //wheel is going up and 
              this.transition = TRANSITION_INACTIVE;
              this.translateYPx = bottom;
              if (this.wheelFloat === 'left') {
                this.wheelFloat = 'right'
              } else if (this.wheelFloat === 'right') {
                this.wheelFloat = 'left'
              }
            } else {
              this.transition = TRANSITION_ACTIVE;
              this.translateYPx = this.translateYPx + 100 * sign;
            }
          }
        }
      }
    },
    actionRegister() {
      if (!this.hidden) {
        this.$router.push('/register')
      }
    },
    actionLogin() {
      if (!this.hidden) {
        this.$router.push('/login')
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.circle-in-layout {
  position: absolute;
  z-index: 950;
}

.circle-right {
  right: 0px;
}

.main_circle {
  cursor: pointer;
  margin-left: 30px;
  margin-right: 30px;
}

.circle {
  position: relative;
  width: 184px;
  height: 184px;
  border-radius: 50%;
  background-color: $violet;
}

.circle *, .arrow * {
  color: white;
}

.circle__hide_left {
  -webkit-transform: translate(-95%);
      -ms-transform: translate(-95%);
          transform: translate(-95%);
}

.circle__hide_right {
  -webkit-transform: translate(95%);
      -ms-transform: translate(95%);
          transform: translate(95%);
}

.circle__animation_hover {
  -webkit-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.circle span[class^="w"]:nth-of-type(n + 0) {
  display: block;
  position: absolute;
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.circle span {
  font-family: "Oswald";
  font-size: 18px;
  font-weight: 200;
  font-style: normal;
  line-height: 2.7;
  white-space: pre;
  overflow: visible;
  padding: 0px;
}

.circle .w0 {
  -webkit-transform: rotate(1.92rad);
  -ms-transform: rotate(1.92rad);
  transform: rotate(1.92rad);
  width: 12px;
  height: 48px;
  left: -1.14px;
  top: 12.67px;
}

.circle .w1 {
  -webkit-transform: rotate(1.73rad);
  -ms-transform: rotate(1.73rad);
  transform: rotate(1.73rad);
  width: 11px;
  height: 48px;
  left: -5.2px;
  top: 29.54px;
}

.circle .w2 {
  -webkit-transform: rotate(1.58rad);
  -ms-transform: rotate(1.58rad);
  transform: rotate(1.58rad);
  width: 6px;
  height: 48px;
  left: -4px;
  top: 43.97px;
}

.circle .w3 {
  -webkit-transform: rotate(1.41rad);
  -ms-transform: rotate(1.41rad);
  transform: rotate(1.41rad);
  width: 12px;
  height: 48px;
  left: -5.95px;
  top: 58.91px;
}

.circle .w4 {
  -webkit-transform: rotate(1.21rad);
  -ms-transform: rotate(1.21rad);
  transform: rotate(1.21rad);
  width: 13px;
  height: 48px;
  left: -1.83px;
  top: 76.8px;
}

.circle .w5 {
  -webkit-transform: rotate(1.05rad);
  -ms-transform: rotate(1.05rad);
  transform: rotate(1.05rad);
  width: 5px;
  height: 48px;
  left: 8.47px;
  top: 90.39px;
}

.circle .w6 {
  -webkit-transform: rotate(0.9rad);
  -ms-transform: rotate(0.9rad);
  transform: rotate(0.9rad);
  width: 11px;
  height: 48px;
  left: 13.28px;
  top: 102px;
}

.circle .w7 {
  -webkit-transform: rotate(0.7rad);
  -ms-transform: rotate(0.7rad);
  transform: rotate(0.7rad);
  width: 13px;
  height: 48px;
  left: 24.74px;
  top: 114.95px;
}

.circle .w8 {
  -webkit-transform: rotate(0.54rad);
  -ms-transform: rotate(0.54rad);
  transform: rotate(0.54rad);
  width: 5px;
  height: 48px;
  left: 40.89px;
  top: 123.72px;
}

.circle .w9 {
  -webkit-transform: rotate(0.38rad);
  -ms-transform: rotate(0.38rad);
  transform: rotate(0.38rad);
  width: 12px;
  height: 48px;
  left: 50.33px;
  top: 130.22px;
}

.circle .w10 {
  -webkit-transform: rotate(0.19rad);
  -ms-transform: rotate(0.19rad);
  transform: rotate(0.19rad);
  width: 11px;
  height: 48px;
  left: 67.57px;
  top: 135.23px;
}

.circle .w11 {
  -webkit-transform: rotate(0rad);
  -ms-transform: rotate(0rad);
  transform: rotate(0rad);
  width: 13px;
  height: 48px;
  left: 84.46px;
  top: 136.99px;
}

.circle .w12 {
  -webkit-transform: rotate(-0.17rad);
  -ms-transform: rotate(-0.17rad);
  transform: rotate(-0.17rad);
  width: 6px;
  height: 48px;
  left: 103.38px;
  top: 135.7px;
}

.circle .w13 {
  -webkit-transform: rotate(-0.33rad);
  -ms-transform: rotate(-0.33rad);
  transform: rotate(-0.33rad);
  width: 10px;
  height: 48px;
  left: 114.95px;
  top: 132.32px;
}

.circle .w14 {
  -webkit-transform: rotate(-0.5rad);
  -ms-transform: rotate(-0.5rad);
  transform: rotate(-0.5rad);
  width: 11px;
  height: 48px;
  left: 129.57px;
  top: 125.75px;
}

.circle .w15 {
  -webkit-transform: rotate(-0.69rad);
  -ms-transform: rotate(-0.69rad);
  transform: rotate(-0.69rad);
  width: 11px;
  height: 48px;
  left: 143.65px;
  top: 116.28px;
}

.circle .w16 {
  -webkit-transform: rotate(-0.88rad);
  -ms-transform: rotate(-0.88rad);
  transform: rotate(-0.88rad);
  width: 12px;
  height: 48px;
  left: 155.58px;
  top: 104px;
}

.circle .w17 {
  -webkit-transform: rotate(-1.04rad);
  -ms-transform: rotate(-1.04rad);
  transform: rotate(-1.04rad);
  width: 5px;
  height: 48px;
  left: 167.47px;
  top: 92.19px;
}

.circle .w18 {
  -webkit-transform: rotate(-1.16rad);
  -ms-transform: rotate(-1.16rad);
  transform: rotate(-1.16rad);
  width: 5px;
  height: 48px;
  left: 172.53px;
  top: 82.43px;
}

.circle .w19 {
  -webkit-transform: rotate(-1.28rad);
  -ms-transform: rotate(-1.28rad);
  transform: rotate(-1.28rad);
  width: 5px;
  height: 48px;
  left: 176.4px;
  top: 72.14px;
}

.circle .w20 {
  -webkit-transform: rotate(-1.43rad);
  -ms-transform: rotate(-1.43rad);
  transform: rotate(-1.43rad);
  width: 12px;
  height: 48px;
  left: 176.07px;
  top: 58px;
}

.circle .w21 {
  -webkit-transform: rotate(-1.62rad);
  -ms-transform: rotate(-1.62rad);
  transform: rotate(-1.62rad);
  width: 11px;
  height: 48px;
  left: 177.39px;
  top: 40.55px;
}

.circle .w22 {
  -webkit-transform: rotate(-1.78rad);
  -ms-transform: rotate(-1.78rad);
  transform: rotate(-1.78rad);
  width: 6px;
  height: 48px;
  left: 178.05px;
  top: 26.18px;
}

.circle .w23 {
  -webkit-transform: rotate(-1.94rad);
  -ms-transform: rotate(-1.94rad);
  transform: rotate(-1.94rad);
  width: 12px;
  height: 48px;
  left: 170.8px;
  top: 11.81px;
}

.circle .w24 {
  -webkit-transform: rotate(-2.15rad);
  -ms-transform: rotate(-2.15rad);
  transform: rotate(-2.15rad);
  width: 13px;
  height: 48px;
  left: 161.95px;
  top: -4.66px;
}

.circle .w25 {
  -webkit-transform: rotate(-2.31rad);
  -ms-transform: rotate(-2.31rad);
  transform: rotate(-2.31rad);
  width: 5px;
  height: 48px;
  left: 156.86px;
  top: -16.57px;
}

.circle .w26 {
  -webkit-transform: rotate(-2.46rad);
  -ms-transform: rotate(-2.46rad);
  transform: rotate(-2.46rad);
  width: 11px;
  height: 48px;
  left: 143.74px;
  top: -26.22px;
}

.circle .w27 {
  -webkit-transform: rotate(-2.66rad);
  -ms-transform: rotate(-2.66rad);
  transform: rotate(-2.66rad);
  width: 13px;
  height: 48px;
  left: 127.78px;
  top: -36.19px;
}

.circle .w28 {
  -webkit-transform: rotate(-2.82rad);
  -ms-transform: rotate(-2.82rad);
  transform: rotate(-2.82rad);
  width: 5px;
  height: 48px;
  left: 118.03px;
  top: -42.14px;
}

.circle .w29 {
  -webkit-transform: rotate(-2.97rad);
  -ms-transform: rotate(-2.97rad);
  transform: rotate(-2.97rad);
  width: 11px;
  height: 48px;
  left: 101.48px;
  top: -45.61px;
}

.circle .w30 {
  -webkit-transform: rotate(-3.17rad);
  -ms-transform: rotate(-3.17rad);
  transform: rotate(-3.17rad);
  width: 13px;
  height: 48px;
  left: 82.56px;
  top: -46.98px;
}

.circle .w31 {
  -webkit-transform: rotate(-3.37rad);
  -ms-transform: rotate(-3.37rad);
  transform: rotate(-3.37rad);
  width: 13px;
  height: 48px;
  left: 63.74px;
  top: -44.63px;
}

.circle .w32 {
  -webkit-transform: rotate(-3.54rad);
  -ms-transform: rotate(-3.54rad);
  transform: rotate(-3.54rad);
  width: 6px;
  height: 48px;
  left: 52.51px;
  top: -39.88px;
}

.circle .w33 {
  -webkit-transform: rotate(-3.71rad);
  -ms-transform: rotate(-3.71rad);
  transform: rotate(-3.71rad);
  width: 13px;
  height: 48px;
  left: 35.28px;
  top: -32.73px;
}

.circle .w34 {
  -webkit-transform: rotate(-7.03rad);
  -ms-transform: rotate(-7.03rad);
  transform: rotate(-7.03rad);
  width: 16px;
  height: 24px;
  left: 494.44px;
  top: 314.25px;
}

.circle .w35 {
  -webkit-transform: rotate(-7.22rad);
  -ms-transform: rotate(-7.22rad);
  transform: rotate(-7.22rad);
  width: 22px;
  height: 24px;
  left: 507.59px;
  top: 296.23px;
}

.circle .w36 {
  -webkit-transform: rotate(-7.37rad);
  -ms-transform: rotate(-7.37rad);
  transform: rotate(-7.37rad);
  width: 9px;
  height: 24px;
  left: 524.82px;
  top: 279.06px;
}

.circle .w37 {
  -webkit-transform: rotate(-7.48rad);
  -ms-transform: rotate(-7.48rad);
  transform: rotate(-7.48rad);
  width: 11px;
  height: 24px;
  left: 530.03px;
  top: 265.62px;
}

.circle .w38 {
  -webkit-transform: rotate(-7.59rad);
  -ms-transform: rotate(-7.59rad);
  transform: rotate(-7.59rad);
  width: 13px;
  height: 24px;
  left: 534.65px;
  top: 249.03px;
}

.circle .w39 {
  -webkit-transform: rotate(-7.69rad);
  -ms-transform: rotate(-7.69rad);
  transform: rotate(-7.69rad);
  width: 13px;
  height: 24px;
  left: 538.51px;
  top: 231.94px;
}

.circle .w40 {
  -webkit-transform: rotate(-7.79rad);
  -ms-transform: rotate(-7.79rad);
  transform: rotate(-7.79rad);
  width: 16px;
  height: 24px;
  left: 539.41px;
  top: 212.32px;
}

.arrow {
  position: absolute;
  top: 80px;
  left: 80px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
} 

.arrow__hide {
  display: none;
}

.arrow__hide_right {
  -webkit-transform: translate(100%);
      -ms-transform: translate(100%);
          transform: translate(100%);
}

</style>
