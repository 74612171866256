import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import App from "./App.vue";

// Plugins
import router from "@/router";
import "@/plugins/bootstrap-vue";
import "@/plugins/vue-cookie";
import "@/plugins/matomo";
import "@/plugins/resize-text";

// Styles
import "@/assets/css/custom.scss";

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
  mounted () {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount("#app");
