export function isSafari() {
  return (
    navigator.vendor &&
    navigator.vendor.indexOf("Apple") > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf("CriOS") == -1 &&
    navigator.userAgent.indexOf("FxiOS") == -1
  );
}

export function windowHeight() {
  const body = document.body,
    html = document.documentElement;

  return Math.max( body.scrollHeight, body.offsetHeight, 
                       html.clientHeight, html.scrollHeight, html.offsetHeight );
}

export function bottomHeight() {
  const body = document.body,
    html = document.documentElement;
    return  body.clientHeight - 300;
}

export function scrollTop() {
  const body = document.body,
    html = document.documentElement;

    return Math.max(body.scrollTop, html.scrollTop);
}