import router from '@/router.js'

router.afterEach((to, from) => {
    window._paq.push(['setReferrerUrl', from.fullPath]);
    window._paq.push(['setCustomUrl', to.fullPath]);
    // window._paq.push(['setDocumentTitle', to.name]);

    // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
    window._paq.push(['deleteCustomVariables', 'page']); 
    window._paq.push(['trackPageView']);
})