<template>
  <b-container class="menu" fluid>
    <b-row>
      <b-col cols="0" sm="0" md="0" lg="0" xl="2" />
      <b-col cols="12" sm="12" md="12" lg="12" xl="6">
        <b-navbar toggleable="xl" type="light" variant="white">
          <div class="collapsed-nav-style">
            <b-navbar-toggle target="nav-collapse">
              <div
                class="main-item"
                :class="{ 'close': menuClose }"
                @click="menuClose = !menuClose"
              >
                <span class="line line01"></span>
                <span class="line line02"></span>
                <span class="line line03"></span>
              </div>
                            
            </b-navbar-toggle>
            <img class="d-xl-none" :class="{ 'hide-element': !menuClose }" width="120" src="@/assets/logo/mj-logo-brandname-sm.png" alt="Moonjelly">
            <router-link to="/">
              <img class="d-xl-none" :class="{ 'hide-element': menuClose }" height="30" src="@/assets/logo/mj-logo-blue.png" alt="Logo">
            </router-link>
          </div>
          <b-collapse id="nav-collapse" v-model="visible" is-nav>
            <div class="collapsed-nav-menu-el">
              <b-navbar-nav class="navbar-toper" fill>
                <b-nav-item
                  @click="menuClose = !menuClose"
                  class="text-left drop-item-format"
                  :to="{ name: 'mainHome' }"
                >
                  Main
                </b-nav-item>
                <b-nav-item
                  @click="menuClose = !menuClose"
                  class="text-left drop-item-format"
                  :to="{ name: 'whatIs' }"
                >
                  What is <img src="@/assets/logo/blue-logo-menu.svg" alt="Moonjelly"> ?
                </b-nav-item>
                <b-nav-item
                  @click="menuClose = !menuClose"
                  class="text-left drop-item-format"
                  :to="{ name: 'manifest' }"
                >
                  Manifest
                </b-nav-item>
                <!-- <b-nav-item
                  @click="menuClose = !menuClose"
                  class="text-left drop-item-format"
                  :to="{ name: 'roadmap' }"
                >
                  Roadmap
                </b-nav-item> -->
                <b-nav-item
                  @click="menuClose = !menuClose"
                  class="text-left drop-item-format"
                  :to="{ name: 'contact' }"
                >
                  Pricing + Contact
                </b-nav-item>
              </b-navbar-nav>
              <!-- Right aligned nav items  MOBILE -->
              <b-navbar-nav
                class="custom-navbar d-xl-none"
              >
                <b-nav-item
                  v-for="(lang, index) in languages"
                  :key="index"
                  @click="langSelect(lang)"
                  class="custom-navbar-item"
                  >{{ lang }}
                </b-nav-item>
              </b-navbar-nav>
            </div>
            <div class="register">
              <b-link @click="toRegisterFromMenu">REGISTER</b-link> OR
              <b-link @click="toLoginFromMenu">LOGIN</b-link> HERE
            </div>
          </b-collapse>
        </b-navbar>
      </b-col>
      <b-col cols="0" sm="0" md="0" lg="0" xl="2">
        <div class="text-center mj-brand d-none d-xl-block" href="/">
          <router-link to="/">
            <img width="120" src="@/assets/logo/mj-logo-brandname-sm.png" alt="Moonjelly" />
          </router-link>
        </div>
      </b-col>
      <b-col cols="0" sm="0" md="0" lg="0" xl="2">
        <div class="d-none d-xl-block">
          <b-nav class="mj-lang">
            <b-nav-item-dropdown
              right
              :text="selectedLang"
              toggle-class="mj__lang_style"
              class="menu__lang border border-primary ml-auto"
            >
              <b-dropdown-item
                v-for="(lang, index) in languages"
                :key="index"
                href="#"
                @click="selectedLang = lang"
                >{{ lang }}</b-dropdown-item
              >
            </b-nav-item-dropdown>
          </b-nav>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  created() {
    this.selectedLang = this.languages[0];
  },
  data() {
    return {
      languages: ["EN"], //["EN", "PL", "ES"]
      selectedLang: "",
      menuClose: false,
      visible: false
    };
  },
  methods: {
    langSelect: function(lg) {
      this.selectedLang = lg
      this.menuClose = !this.menuClose
    },
    toRegisterFromMenu: function() {
      this.menuClose = !this.menuClose
      this.visible = false
      this.$router.push('/register', () => {})
    },
    toLoginFromMenu: function() {
      this.menuClose = !this.menuClose
      this.visible = false
      this.$router.push('/login', () => {})
    }
  }
};
</script>

<style lang="scss" scoped>
.menu {
  text-transform: uppercase;
  position: fixed;
  background-color: #fff;
  border-bottom: 1px solid #707070;
  z-index: 1000;
}

.nav-link {
  color: #000 !important;
}

.router-link-exact-active {
  text-decoration: underline !important;
  color:#ad83ff !important;
}

.menu__lang {
  border-radius: 50rem !important;
  padding: 0 0.8rem 0 0.8rem;
}

.custom-navbar {
  padding-top: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.custom-navbar-item {
  border-radius: 50rem;
  padding: 0 1rem 0 1rem;
  border: 1px solid #0500ff;
  margin-right: 10px;
  color: #ad83ff !important;
  font-size: 0.7rem;
}

.register {
  display: none;
}

.hide-element {
  display: none;
}

.custom-navbar-item > a {
  color: #ad83ff !important;
}

.mj-brand {
  padding: 0.5rem 0 0.5rem 0;
}

.mj-lang {
  padding: 0.6rem 0 0.6rem 0;
}

.navbar-toper {
  display: contents;
}

.navbar-toper > li > a {
  line-height: 1rem;
}

.collapsed-nav-menu-el {
  display: contents;
}

@media (max-width: 1199px) {
  .menu {
    padding: 0;
  }
  .navbar {
    padding: 0;
  }
  .collapsed-nav-style {
    padding: 1rem 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .collapsed-nav-menu-el {
    padding: 0.5rem 15px 0.5rem 15px;
    display: block;
  }
  .navbar-collapse {
    padding-top: 2rem;
  }
  .drop-item-format {
    padding: 0.3rem 0 0.3rem 0;
  }
  .nav-link {
    color: #0500ff !important;
    font-family: "Good Sans Regular";
  }
  .register {
    font-family: "Good Sans Regular";
    display: block;
    background: #ad83ff;
    color: #fff;
    text-align: center;
    padding: 2rem 0;
    font-size: 18px;
    // margin: 16px -32px -8px -32px;
  }
  .register > a {
    color: #fff;
    text-decoration: underline;
  }
  .navbar-brand {
    position: absolute;
    right: 20%;
    top: 8px;
  }
  .navbar-toggler,
  .navbar-toggler:focus,
  .navbar-toggler:active,
  .navbar-toggler:hover,
  .navbar-toggler:visited {
    border: 0 !important;
    outline: 0 !important;
  }
  .main-item {
    width: 32px;
    height: 32px;
    position: relative;
  }

  .line {
    position: absolute;
    height: 3px;
    width: 100%;
    background: #0500ff;
    border-radius: 10px;
    transition: all cubic-bezier(0.25, 0.1, 0.28, 1.54) 0.32s;
  }

  .line01 {
    top: 19%;
  }

  .line02 {
    top: 49%;
  }

  .line03 {
    top: 79%;
  }

  .main-item.close .line01 {
    transform: rotate(45deg);
    top: 49%;
  }

  .main-item.close .line02,
  .main-item.close .line03 {
    transform: rotate(-45deg);
    top: 49%;
  }

  .navbar-light .navbar-toggler {
    padding: 0;
    margin-left: -15px;
  }
}
</style>
